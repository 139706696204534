import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import {
  CloseNomenclatureIssueViewModel,
  IssueStatus,
  IssueViewModel,
  UserShortViewModel,
} from 'schema/serverTypes';
import { Link } from 'react-router-dom';
import { AddComment } from './AddComment';
import { CommentList } from './CommentList';
import { IconSprite } from '../icons';
import { themeOrange as theme } from 'theme';
import { useCallback, useState } from 'react';
import {
  useCloseNomenclatureIssueMutation,
  useIssueDeleteMutation,
  useUpdateIssueStatusMutation,
  useUsersBackendQuery1,
} from '../../services/api';
import { Button } from '../Button';
import { IssueEditForm } from './IssueEditForm';
import { ModalForm, useModalForm } from '../Modal';
import { Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import { Role } from '../authentication';
import { useUserRole } from '../../services/authentication';
import { Input } from '../form';
import { SearchingNomenclature } from './SearchingNomeclature';
import { useForm } from 'react-hook-form';
import { useQueryClient } from 'react-query';
import useFileDownloader from '../utils/useFileDownloader';
import { useToast } from '../Toast';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      color: theme.palette.common.black,
      fontSize: 14,
    },
    issue: {
      background: 'white',
      marginBottom: theme.spacing(4),
    },
    title: {
      height: 47,
      borderBottom: '1px solid #E3E6F3',
      padding: theme.spacing(0, 2),
      color: theme.palette.primary.main,
      fontWeight: 700,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
    },
    action: {
      '& + &': {
        marginLeft: theme.spacing(0.5),
      },
    },
    description: {
      padding: theme.spacing(1.5, 2),
      '& ol': {
        paddingLeft: theme.spacing(2),
        '& > li': {
          listStyleType: 'decimal',
          marginBottom: theme.spacing(1),
        },
      },
      '& ul': {
        paddingLeft: theme.spacing(2),
        '& > li': {
          listStyleType: 'initial',
          marginBottom: theme.spacing(1),
        },
      },
    },
    link: {
      padding: theme.spacing(0, 2, 2),
      fontFamily: "'Halvar Breit', Helvetica, Arial, sans-serif",
      fontSize: 12,
      fontWeight: 500,
      display: 'inline-block',
      color: theme.palette.secondary.dark,
      '& a': {
        color: theme.palette.secondary.dark,
        textDecoration: 'none',
      },
    },
    nomenclatureWrapper: {
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(1.5),
      padding: '0 16px',
      alignItems: 'flex-start',
    },
    nomenclatureLabel: {
      fontSize: 9,
      color: theme.palette.text.secondary,
    },
    nomenclatureName: {
      fontSize: 12,
      color: theme.palette.secondary.dark,
    },
    actions: {
      position: 'absolute',
      right: 1,
      top: 0,
      bottom: 0,
      display: 'flex',
      alignItems: 'center',
      '& *': {
        marginRight: 9,
        cursor: 'pointer',
      },
    },
    file: {
      cursor: 'pointer',
      position: 'relative',
      margin: theme.spacing(0, 1.5, 1.5),
      padding: theme.spacing(1.5, 1.25, 1),
      backgroundColor: theme.palette.lightBlue3.main,
      color: theme.palette.primary.main,
    },
    edit: {
      height: 47,
      borderTop: '1px solid #E3E6F3',
      padding: theme.spacing(0, 2),
      color: theme.palette.primary.main,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
    },
    modalRoot: {
      textAlign: 'center',
    },
    modalTitle: {
      textAlign: 'center',
      marginBottom: theme.spacing(3),
    },
    modalText: {
      color: theme.palette.text.primary,
    },
    modalSelect: {
      textAlign: 'left',
    },
    modalButton: {
      marginTop: theme.spacing(2),
      '& + &': {
        marginLeft: theme.spacing(2.5),
      },
    },
  })
);

export const IssueNomenclature = (props: { issue: IssueViewModel }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const toast = useToast();
  const [isEditMode, setEditMode] = useState<boolean>(false);
  const {
    description = '',
    comments = [],
    statusHistory = [],
    file,
    id,
    author,
    quotaId,
    nomenclature,
    status,
  } = props.issue;
  const { onOpen, onClose, open } = useModalForm();
  const {
    onOpen: onOpenCodePopup,
    onClose: onCloseCodePopup,
    open: openCodePopup,
  } = useModalForm();
  const { isAdmin, isSuperSalesManager, isSalesManager, isSalesSupport } = useUserRole();
  const ownerId = author.id;
  const { data } = useUsersBackendQuery1<UserShortViewModel>({
    relativeUrl: 'me',
    queryKey: 'me',
  });

  const meId = data?.id;
  const isOwner = meId === ownerId;
  const isRules = isAdmin || ((isSuperSalesManager || isSalesManager || isSalesSupport) && isOwner);

  const downloadFile = useFileDownloader();
  const getFile = useCallback(
    async (ev) => {
      await downloadFile(ev, file?.downloadUrl ?? '', file?.fileName ?? '');
    },
    [downloadFile, file?.fileName, file?.downloadUrl]
  );

  const editModeHandler = () => {
    setEditMode((prev) => !prev);
  };

  const deleteHandler = useCallback(
    (id) => {
      onOpen();
    },
    [onOpen]
  );

  const { push } = useHistory();

  const { mutateAsync } = useIssueDeleteMutation(props.issue.id, {
    method: 'DELETE',
    onSuccess: () => {
      push('/tasks');
    },
  });

  const confirmDeleteHandler = useCallback(() => {
    mutateAsync(null);
    onClose();
  }, [onClose, mutateAsync]);

  const {
    control: sendCodeControl,
    handleSubmit: handleSendCodeSubmit,
    watch,
  } = useForm<CloseNomenclatureIssueViewModel>({
    mode: 'onBlur',
  });

  const code = watch('code');

  const { mutateAsync: sendCodeAsync } = useCloseNomenclatureIssueMutation(props.issue.id, {
    onError: () => {
      toast(t('ErrorMessage'), 'error');
    },
  });
  const { mutateAsync: updateStatusAsync } = useUpdateIssueStatusMutation(id);

  const queryClient = useQueryClient();

  const handleSendCode = useCallback(() => {
    const onSubmit = async (data: CloseNomenclatureIssueViewModel) => {
      const issue = await sendCodeAsync(data);
      if (issue) {
        await updateStatusAsync({ status: IssueStatus.Closed });
        queryClient.invalidateQueries({
          predicate: (query) => {
            return true;
          },
        });
        onCloseCodePopup();
      }
    };
    handleSendCodeSubmit(onSubmit)();
  }, [sendCodeAsync, handleSendCodeSubmit, updateStatusAsync, queryClient, onCloseCodePopup]);

  return (
    <>
      <div className={classes.root}>
        <div className={classes.issue}>
          {!isEditMode ? (
            <>
              <div className={classes.title}>
                <Typography variant={'h4'} color="primary">
                  Запрос на согласование номенклатуры расчет №{quotaId}
                </Typography>
                <div>
                  {isRules && (
                    <>
                      <Button
                        variant="iconButton"
                        endIcon={<IconSprite icon={'edit'} width={'14px'} />}
                        onClick={editModeHandler}
                        className={classes.action}
                      />
                      <Button
                        variant="iconButton"
                        endIcon={<IconSprite icon={'delete'} width={'14px'} />}
                        onClick={deleteHandler}
                        className={classes.action}
                      />
                    </>
                  )}
                </div>
              </div>
              <div className={classes.nomenclatureWrapper}>
                <div className={classes.nomenclatureLabel}>
                  {t('Lease subject')}
                  <br />
                  {nomenclature?.name && (
                    <span className={classes.nomenclatureName}>{nomenclature.name}</span>
                  )}
                </div>
              </div>
              {description && (
                <div
                  className={classes.description}
                  dangerouslySetInnerHTML={{ __html: description }}
                />
              )}
              {file && (
                <div className={classes.file} onClick={getFile}>
                  {file.fileName}
                  <div className={classes.actions}>
                    <div>
                      <IconSprite
                        icon="download"
                        width="14px"
                        height="13px"
                        color={theme.palette.text.primary}
                        hoverColor={theme.palette.primary.main}
                      />
                    </div>
                  </div>
                </div>
              )}
              <div className={classes.link}>
                <Link to={`/quotas/calculator/${quotaId}`}>Расчет №{quotaId}</Link>
              </div>
              {status === IssueStatus.Closed && (
                <div className={classes.edit}>
                  <div className={classes.nomenclatureLabel}>
                    Код из 1C
                    <br />
                    {nomenclature?.code && (
                      <span className={classes.nomenclatureName}>{nomenclature.code}</span>
                    )}
                  </div>
                  <div className={classes.nomenclatureLabel}>
                    Номенклатура итоговая
                    <br />
                    {nomenclature?.name && (
                      <span className={classes.nomenclatureName}>{nomenclature.name}</span>
                    )}
                  </div>
                  <Button
                    variant="iconButton"
                    endIcon={<IconSprite icon={'edit'} width={'14px'} />}
                    onClick={onOpenCodePopup}
                    className={classes.action}
                  />
                </div>
              )}
            </>
          ) : (
            <IssueEditForm issue={props.issue} editModeHandler={editModeHandler} />
          )}
        </div>
        <Role>{props.issue.status !== 'closed' && <AddComment issueId={props.issue.id} />}</Role>
        {!!(comments?.length || statusHistory?.length) && (
          <CommentList comments={comments} issueId={id} statusHistory={statusHistory} />
        )}
      </div>
      <ModalForm open={open} onClose={onClose} width={500}>
        <div className={classes.modalRoot}>
          <Typography variant={'h2'} className={classes.modalTitle}>
            {t('Delete Task')}
          </Typography>
          <Typography variant="subtitle2" className={classes.modalText}>
            {t('Are you sure you want to delete the task')}
          </Typography>
          <Typography variant="body2" className={classes.modalText}>
            №{props.issue.id} {props.issue.title}?
          </Typography>
          <div>
            <Button
              variant="contained"
              className={classes.modalButton}
              onClick={confirmDeleteHandler}
            >
              {t('Yes')}
            </Button>
            <Button variant="outlined" onClick={onClose} className={classes.modalButton}>
              {t('No')}
            </Button>
          </div>
        </div>
      </ModalForm>

      <ModalForm open={openCodePopup} onClose={onCloseCodePopup} width={500}>
        <div className={classes.modalRoot}>
          <Typography variant={'h2'} className={classes.modalTitle}>
            {t('Редактировать код номенклатуры')}
          </Typography>
          <form className={classes.modalSelect}>
            <Input
              label={t('Введите код из 1-С')}
              name="code"
              control={sendCodeControl}
              rules={{
                required: {
                  value: true,
                  message: t('Required'),
                },
              }}
            />
            <SearchingNomenclature code={code} />
          </form>
          <div>
            <Button variant="contained" className={classes.modalButton} onClick={handleSendCode}>
              {t('Ok')}
            </Button>
            <Button variant="outlined" onClick={onCloseCodePopup} className={classes.modalButton}>
              {t('Cancel')}
            </Button>
          </div>
        </div>
      </ModalForm>
    </>
  );
};
